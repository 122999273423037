.tabs .overflow-carousel {
  max-width: 100%;
  width: 100%;
}
.tabs .overflow-carousel__content {
  padding: 0;
}
.tabs__tab {
  position: relative;
  background: none;
  border: 0;
  color: #767676;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  margin: 0;
  padding: 1rem 0.25rem;
  text-align: left;
}
.tabs__tab .svg-icon {
  margin-right: 0.5rem;
}
.tabs__tab:not(:disabled):hover {
  color: #484848;
}
.tabs__tab:disabled {
  cursor: default;
}
.tabs__tab--active {
  color: #111111;
  cursor: default;
}
.tabs__tab--active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  border-radius: 1.5px;
  border-bottom: 0.1875rem solid #0058a3;
  z-index: 100;
}
.tabs__tab--active:not(:disabled):hover {
  color: #111111;
}
.tabs__tab + .tabs__tab {
  margin-left: 1.5rem;
}
.tabs__tab:disabled {
  color: #dfdfdf;
}
.tabs__list {
  position: relative;
  display: flex;
  width: 100%;
}
.tabs__list::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  border-bottom: 1px solid #dfdfdf;
  z-index: -1;
}
.tabs__panel {
  outline: none;
  padding: 2.5rem 0;
  position: relative;
}
.tabs__panel-title {
  color: #111111;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  font-weight: 700;
}
